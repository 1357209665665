import React from "react";
import { Dialog, DialogContent } from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { Info, SquareArrowOutUpRight } from "lucide-react";
import { Card, CardContent } from "@/components/ui/card";
import { ambit } from "@/pages/_app";
import { cn } from "@/lib/utils";

export interface PackageModalProps {
  onClose: () => void;
  email: string;
}

const PackageModal: React.FC<PackageModalProps> = ({ onClose, email }) => {
  return (
    <Dialog open={true} onOpenChange={onClose}>
      <DialogContent
        className={cn(
          ambit.variable,
          "max-w-[90%] overflow-hidden rounded-xl p-0 font-sans sm:rounded-2xl md:max-w-2xl",
        )}
      >
        <div className="space-y-9 bg-gradient-to-b from-[#FFFFFE] to-[#C3DEFB]/30 px-4 pb-7 pt-10">
          <div className="flex flex-col items-center space-y-4">
            <h2 className="text-navy-900 text-center text-2xl font-semibold tracking-wider md:text-4xl md:tracking-wide">
              Thanks for your Order!
            </h2>
            <p className="text-center leading-snug tracking-[0.02em] text-gray-600">
              You will immediately receive an invoice and all items at{" "}
              <span className="text-gray-900">{email}</span>
            </p>
          </div>
          <Card className="border border-[#F1EFEF] bg-[#FFFFFE] shadow-md">
            <CardContent className="space-y-3 p-4">
              <div className="flex items-center gap-2">
                <Info className="h-6 w-6 text-white" fill="#3772FF" />
                <h3 className="text-base font-semibold tracking-wide text-gray-900 md:text-lg">
                  Haven&apos;t received our email?
                </h3>
              </div>
              <ul className="ml-2 space-y-2 text-gray-600">
                <li className="flex items-center gap-2.5">
                  <div className="h-1.5 w-1.5 rounded-full bg-[#CACFD8] text-sm md:text-base" />
                  Check your spam/junk folder
                </li>
                <li className="flex items-center gap-2.5">
                  <div className="h-1.5 w-1.5 rounded-full bg-[#CACFD8] text-sm md:text-base" />
                  Look in your Promotions tab (Gmail)
                </li>
                <li className="flex items-center gap-2.5">
                  <div className="h-1.5 w-1.5 rounded-full bg-[#CACFD8]" />
                  <span>
                    Contact us at{" "}
                    <a href="mailto:support@crayo.ai" className="text-blue-600 hover:underline">
                      support@crayo.ai
                    </a>
                  </span>
                </li>
              </ul>
            </CardContent>
          </Card>
        </div>
        <div className="flex justify-center px-6 pb-4">
          <Button
            className="w-full rounded-full bg-[#3772FF] py-2.5 text-base font-light"
            onClick={() => (window.location.href = `mailto:${email}`)}
          >
            <span className="flex items-center font-normal tracking-wide">
              Open Email <SquareArrowOutUpRight className="ml-2 h-4 w-4" />
            </span>
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default PackageModal;
