import React from "react";
import { useModalContext } from "@/lib/contexts/ModalContext";
import PackageModal, { type PackageModalProps } from "./PackageModal";
import BfcmModal from "./BfcmModal";

const ModalManager: React.FC = () => {
  const { modals, closeModal } = useModalContext();

  return (
    <>
      {modals.map((modal, index) => {
        switch (modal.type) {
          case "package":
            return (
              <PackageModal
                key={`package-${index}`}
                {...(modal.props as PackageModalProps)}
                onClose={() => closeModal("package")}
              />
            );
          case "bfcm":
            return <BfcmModal key={`bfcm-${index}`} onClose={() => closeModal("bfcm")} />;
          default:
            return null;
        }
      })}
    </>
  );
};

export default ModalManager;
