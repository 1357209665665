import React from "react";
import Image from "next/image";
import { Dialog, DialogContent } from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { ChevronRight } from "lucide-react";
import { Card, CardContent } from "@/components/ui/card";
import { ambit } from "@/pages/_app";
import { cn } from "@/lib/utils";
import { BsFillTrophyFill } from "react-icons/bs";
import { BsLightningChargeFill } from "react-icons/bs";
import { FaCrown } from "react-icons/fa6";

export interface BfcmModalProps {
  onClose: () => void;
}

const BfcmModal: React.FC<BfcmModalProps> = ({ onClose }) => {
  return (
    <Dialog open={true} onOpenChange={onClose}>
      <DialogContent
        className={cn(
          ambit.variable,
          "flex max-h-[90dvh] max-w-[90%] flex-col overflow-y-auto overflow-x-hidden rounded-2xl p-0 font-sans sm:rounded-2xl md:max-w-screen-md",
        )}
      >
        <div className="relative hidden md:block">
          <Image
            src="https://cdn-crayo.com/lp/public/bfcm/gift-bf.png"
            alt="gift-bf"
            width={250}
            height={100}
            className={cn("absolute -left-2 top-[50px] z-50 hidden w-[20%] max-w-lg md:block")}
            draggable={false}
            unoptimized
          />
          <Image
            src="https://cdn-crayo.com/lp/public/bfcm/gift-cm.png"
            alt="gift-cm"
            width={250}
            height={100}
            className={cn("absolute -right-2 top-[44px] z-50 hidden w-[19.5%] max-w-lg md:block")}
            draggable={false}
            unoptimized
          />
        </div>
        <div className="flex w-full flex-col space-y-3 px-2 pb-6 pt-6 md:space-y-10 md:px-5 md:pb-8 md:pt-10">
          <div className="flex w-full flex-col items-center space-y-4 pt-2 md:space-y-6 md:pt-5">
            <Image
              src="https://cdn-crayo.com/lp/public/bfcm/bfcm-hero.svg"
              alt="bf-hero"
              width={100}
              height={100}
              className="w-[50%] max-w-sm md:w-[90%]"
              draggable={false}
              unoptimized
            />
            <p className="max-w-[85%] text-center text-lg text-[#475467] md:text-xl">
              BFCM Blowout — Deals You Can&apos;t Resist!
            </p>
          </div>

          <Card className="border border-[#F1EFEF] bg-[#FFFFFE] shadow-md">
            <div className="top-1 w-full rounded-t-lg bg-[#335CFF] py-2">
              <p className="text-center text-[9px] uppercase tracking-[0.25em] text-white md:text-xs">
                biggest pricing drop ever ⚡ ⚡ ⚡
              </p>
            </div>
            <CardContent
              className={cn(
                "z-50 flex w-full flex-col items-start justify-start space-y-2.5 rounded-b-lg border border-[#3772FF] p-3 md:p-4",
                "md:flex-row md:space-x-2.5 md:space-y-0",
              )}
            >
              {planData.map((plan) => (
                <PlanDiscount key={plan.name} {...plan} />
              ))}
            </CardContent>
          </Card>

          <button
            className="w-full rounded-full bg-[#3772FF] py-2 hover:bg-[#3772FF]/80 md:py-3"
            onClick={() => {
              window.location.href = "https://crayo.ai/dashboard?openPricing=true";
            }}
          >
            <div className="flex flex-row items-center justify-center space-x-0.5">
              <p className="text-sm font-normal text-white md:text-base">Grab Now</p>
              <ChevronRight className="h-4 w-4 text-white" strokeWidth={2.2} />
            </div>
          </button>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default BfcmModal;

const planData = [
  {
    name: "Hobby",
    price: 19,
    salePrice: 3,
    icon: (
      <BsLightningChargeFill className="h-5 w-5 text-[#335CFF] @md/banner:h-4 @md/banner:w-4 md:h-6 md:w-6" />
    ),
  },
  {
    name: "Clipper",
    price: 39,
    salePrice: 5,
    icon: (
      <FaCrown className="h-5 w-5 text-[#335CFF] @md/banner:h-4 @md/banner:w-4 md:h-6 md:w-6" />
    ),
  },
  {
    name: "Pro",
    price: 79,
    salePrice: 10,
    icon: (
      <BsFillTrophyFill className="h-5 w-5 text-[#335CFF] @md/banner:h-4 @md/banner:w-4 md:h-6 md:w-6" />
    ),
  },
];

type Plan = (typeof planData)[number];

const PlanDiscount = ({ name, icon, price, salePrice }: Plan) => {
  return (
    <div
      className={cn(
        "flex w-full flex-row items-center justify-start space-x-4 rounded-2xl bg-[#F6F6F6] py-3 pl-3 md:space-x-6 md:py-4 md:pl-4",
        name === "Clipper" && "bg-[#EFF6FF]",
      )}
    >
      <div className="flex flex-col items-center justify-center space-y-1">
        <div className="w-fit rounded-full border border-[#E5E7ED] bg-white p-2 md:p-3">{icon}</div>
        <p className="text-sm font-semibold tracking-wider text-gray-800 md:text-base">{name}</p>
      </div>
      <div className="flex flex-col items-start justify-center space-y-1 pt-2">
        <div
          className={cn(
            "w-fit rounded-lg bg-[#C5C5C5] px-2 py-0.5 md:px-2.5 md:py-1",
            name === "Clipper" && "bg-[#3772FF]",
          )}
        >
          <p className="text-xs font-normal tracking-wider text-white md:text-base">{`$${price}/m`}</p>
          <div className="relative bottom-[10px] right-1 h-0.5 w-[112%] -rotate-[10deg] transform bg-white md:bottom-[12.5px]" />
        </div>
        <p className="text-xl font-bold tracking-tight text-gray-800 @md/banner:text-xl md:text-3xl">{`$${salePrice}/m`}</p>
      </div>
    </div>
  );
};
